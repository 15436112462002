import {DIGITAL_CERTIFICATE} from '@/contants/form';

export function setDigitalCertificate({commit}, payload) {
  commit('setDigitalCertificate', Object.assign({}, payload))
}

export async function getDigitalCertificate({getters, commit}, payload) {
  if (!payload) {
    return DIGITAL_CERTIFICATE;
  } else {
    let digital_certificate = getters['getDigitalCertificate'];
    if (!digital_certificate || digital_certificate.id != payload) {
      digital_certificate = await this.$frontendRepositories.digital_certificate.getDigitalCertificate(payload)
      commit('setDigitalCertificate', digital_certificate);
    }
    const data = JSON.parse(JSON.stringify(digital_certificate));
    return data
  }
}

export async function storeStep1({}, payload) {
  const data = await this.$frontendRepositories.digital_certificate.storeStep1(payload);
  return data;
}

export async function storeStep2({}, payload) {
  const data = await this.$frontendRepositories.digital_certificate.storeStep2(payload);
  return data;
}

export async function updateDeliverInfo({}, payload) {
  const data = await this.$frontendRepositories.digital_certificate.updateDeliverInfo(payload);
  return data;
}

export async function getRecipientInfo({}, payload) {
  const data = await this.$frontendRepositories.digital_certificate.getRecipientInfo(payload);
  return data;
}
