export const MENU_KEY_ACCOUNT = "account";
export const MENU_KEY_COUPON = "coupon";
export const MENU_KEY_CARD = "card";
export const MENU_KEY_COMPANY = "company";
export const MENU_KEY_SEAL_SUBMITTER = "seal_submitter";
export const MENU_KEY_SEAL_CARD = "seal_card";
export const MENU_KEY_SOFT_ACCOUNT = "soft_account";
export const MENU_KEY_DIGITAL_CERTIFICATE = "digital_certificate";

export const FIRST_LOGIN_COMPANY_MENU = "company";
export const FIRST_LOGIN_SEAL_MENU = "seal";
export const FIRST_LOGIN_DIGITAL_MENU = "digital";

const menus = {
  account: {
    url: '/settings/account',
    title: 'labels.settings.account.name',
    description: 'labels.settings.account.text'
  },
  // coupon: {
  //   url: '/settings/coupon',
  //   title: 'labels.settings.coupon.name',
  //   description: 'labels.settings.coupon.text'
  // },
  // card: {
  //   url: '/settings/card',
  //   title: 'labels.settings.payment.name',
  //   description: 'labels.settings.payment.text'
  // },
  company: {
    url: '/settings/company',
    title: 'labels.settings.company.name',
    description: 'labels.settings.company.text'
  },
  // seal_submitter: {
  //   url: '/settings/seal-submitter',
  //   title: 'labels.settings.submit_persion.name',
  //   description: 'labels.settings.submit_persion.text'
  // },
  // seal_card: {
  //   url: '/settings/seal-card',
  //   title: 'labels.settings.seal_card.name',
  //   description: 'labels.settings.seal_card.text'
  // },
  // soft_account: {
  //   url: '/settings/soft-account',
  //   title: 'labels.settings.soft_account.name',
  //   description: 'labels.settings.soft_account.text'
  // },
  // digital_certificate: {
  //   url: '/settings/digital-certificate',
  //   title: 'labels.settings.digital_certificate.name',
  //   description: 'labels.settings.digital_certificate.text'
  // }
}

export default menus;

export const firstLogin = {
  company: {
    company: {
      ...menus[MENU_KEY_COMPANY],
      url_first_login: '/first-login/company',
    },
  },
  seal: {
    company: {
      ...menus[MENU_KEY_COMPANY],
      url_first_login: '/first-login/seal',
    },
    seal_submitter: {
      ...menus[MENU_KEY_SEAL_SUBMITTER],
      url_first_login: '/first-login/seal/seal-certificate',
    },
    seal_card: {
      ...menus[MENU_KEY_SEAL_CARD],
      url_first_login: '/first-login/seal/seal-card'
    },
    soft_account: {
      ...menus[MENU_KEY_SOFT_ACCOUNT],
      url_first_login: '/first-login/seal/soft-account'
    },
    digital_certificate: {
      ...menus[MENU_KEY_DIGITAL_CERTIFICATE],
      url_first_login: '/first-login/seal/digital-certificate'
    },
  },
  digital: {
    company: {
      ...menus[MENU_KEY_COMPANY],
      url_first_login: '/first-login/digital',
    },
    seal_submitter: {
      ...menus[MENU_KEY_SEAL_SUBMITTER],
      url_first_login: '/first-login/digital/seal-certificate',
    }
  },
};

export const projectSettingRequire = {
  seal: {
    company: {
      ...menus[MENU_KEY_COMPANY],
      url_preset: '/preset/seal',
    },
    seal_submitter: {
      ...menus[MENU_KEY_SEAL_SUBMITTER],
      url_preset: '/preset/seal/seal-certificate',
    },
    seal_card: {
      ...menus[MENU_KEY_SEAL_CARD],
      url_preset: '/preset/seal/seal-card'
    },
    soft_account: {
      ...menus[MENU_KEY_SOFT_ACCOUNT],
      url_preset: '/preset/seal/soft-account'
    },
    digital_certificate: {
      ...menus[MENU_KEY_DIGITAL_CERTIFICATE],
      url_preset: '/preset/seal/digital-certificate'
    },
  },
  digital: {
    company: {
      ...menus[MENU_KEY_COMPANY],
      url_preset: '/preset/digital',
    },
    seal_submitter: {
      ...menus[MENU_KEY_SEAL_SUBMITTER],
      url_preset: '/preset/digital/seal-certificate',
    }
  },
};
