import {FRONTEND_VERSION_API} from "@/contants/api";
const companyResource = `${FRONTEND_VERSION_API}/companies`;

export default $axios => ({
  search(searchText) {
    return $axios.get(`${companyResource}/search-from-api?searchText=${searchText}`);
  },
  getByCurrUserId() {
    return $axios.get(`${companyResource}/get-by-curr-user`);
  },
  create(company) {
    return $axios.post(companyResource, company);
  },
  checkRequireSettingForSealRequest(id) {
    return $axios.get(`${companyResource}/check-require-setting-for-seal-request/${id}`,)
  },
  checkRequireSettingForDigitalRequest(id) {
    return $axios.get(`${companyResource}/check-require-setting-for-digital-request/${id}`,)
  }
});
