import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _be9dc5d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _528b1e7f = () => interopDefault(import('../pages/frontend/index.vue' /* webpackChunkName: "pages/frontend/index" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _e278afee = () => interopDefault(import('../pages/admin/change-sikulix-pass/index.vue' /* webpackChunkName: "pages/admin/change-sikulix-pass/index" */))
const _29b76c64 = () => interopDefault(import('../pages/admin/companies/index.vue' /* webpackChunkName: "pages/admin/companies/index" */))
const _85773680 = () => interopDefault(import('../pages/admin/contacts/index.vue' /* webpackChunkName: "pages/admin/contacts/index" */))
const _6a58c02f = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _74a5d24a = () => interopDefault(import('../pages/admin/need_payment/index.vue' /* webpackChunkName: "pages/admin/need_payment/index" */))
const _f439320e = () => interopDefault(import('../pages/admin/payment_confirms/index.vue' /* webpackChunkName: "pages/admin/payment_confirms/index" */))
const _a41aa178 = () => interopDefault(import('../pages/admin/revenues/index.vue' /* webpackChunkName: "pages/admin/revenues/index" */))
const _3f2c361f = () => interopDefault(import('../pages/admin/status/index.vue' /* webpackChunkName: "pages/admin/status/index" */))
const _808f695e = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _64e3dce6 = () => interopDefault(import('../pages/frontend/contact.vue' /* webpackChunkName: "pages/frontend/contact" */))
const _027b2eae = () => interopDefault(import('../pages/frontend/contact-complete.vue' /* webpackChunkName: "pages/frontend/contact-complete" */))
const _11b762f8 = () => interopDefault(import('../pages/frontend/dashboard/index.vue' /* webpackChunkName: "pages/frontend/dashboard/index" */))
const _7aa2e8fc = () => interopDefault(import('../pages/frontend/debug-sentry.vue' /* webpackChunkName: "pages/frontend/debug-sentry" */))
const _d1a87e18 = () => interopDefault(import('../pages/frontend/detail.vue' /* webpackChunkName: "pages/frontend/detail" */))
const _0b1cc95a = () => interopDefault(import('../pages/frontend/digit.vue' /* webpackChunkName: "pages/frontend/digit" */))
const _a9ab9cf6 = () => interopDefault(import('../pages/frontend/preset/index.vue' /* webpackChunkName: "pages/frontend/preset/index" */))
const _24ba1cf5 = () => interopDefault(import('../pages/frontend/privacy.vue' /* webpackChunkName: "pages/frontend/privacy" */))
const _7a7049ff = () => interopDefault(import('../pages/frontend/request/index.vue' /* webpackChunkName: "pages/frontend/request/index" */))
const _38c2e760 = () => interopDefault(import('../pages/frontend/seal.vue' /* webpackChunkName: "pages/frontend/seal" */))
const _aad0ea6a = () => interopDefault(import('../pages/frontend/search.vue' /* webpackChunkName: "pages/frontend/search" */))
const _0100df94 = () => interopDefault(import('../pages/frontend/search-form.vue' /* webpackChunkName: "pages/frontend/search-form" */))
const _6a18df09 = () => interopDefault(import('../pages/frontend/settings/index.vue' /* webpackChunkName: "pages/frontend/settings/index" */))
const _a0e563de = () => interopDefault(import('../pages/frontend/trade.vue' /* webpackChunkName: "pages/frontend/trade" */))
const _d14445c8 = () => interopDefault(import('../pages/frontend/verify.vue' /* webpackChunkName: "pages/frontend/verify" */))
const _42fedfd1 = () => interopDefault(import('../pages/frontend/verify-email-change-email.vue' /* webpackChunkName: "pages/frontend/verify-email-change-email" */))
const _0cb6f7ec = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _24810a34 = () => interopDefault(import('../pages/admin/revenue_reports/chart/index.vue' /* webpackChunkName: "pages/admin/revenue_reports/chart/index" */))
const _13b25e1e = () => interopDefault(import('../pages/admin/revenue_reports/list/index.vue' /* webpackChunkName: "pages/admin/revenue_reports/list/index" */))
const _508e8236 = () => interopDefault(import('../pages/frontend/auth/login.vue' /* webpackChunkName: "pages/frontend/auth/login" */))
const _fb409852 = () => interopDefault(import('../pages/frontend/auth/register.vue' /* webpackChunkName: "pages/frontend/auth/register" */))
const _197b35aa = () => interopDefault(import('../pages/frontend/first-login/company/index.vue' /* webpackChunkName: "pages/frontend/first-login/company/index" */))
const _cec83cf6 = () => interopDefault(import('../pages/frontend/first-login/digital/index.vue' /* webpackChunkName: "pages/frontend/first-login/digital/index" */))
const _efa27874 = () => interopDefault(import('../pages/frontend/first-login/seal/index.vue' /* webpackChunkName: "pages/frontend/first-login/seal/index" */))
const _1df6db4e = () => interopDefault(import('../pages/frontend/preset/digital/index.vue' /* webpackChunkName: "pages/frontend/preset/digital/index" */))
const _2db3da56 = () => interopDefault(import('../pages/frontend/preset/digital-certificate.vue' /* webpackChunkName: "pages/frontend/preset/digital-certificate" */))
const _71ee469d = () => interopDefault(import('../pages/frontend/preset/seal/index.vue' /* webpackChunkName: "pages/frontend/preset/seal/index" */))
const _70c85113 = () => interopDefault(import('../pages/frontend/preset/seal-card.vue' /* webpackChunkName: "pages/frontend/preset/seal-card" */))
const _94a4f238 = () => interopDefault(import('../pages/frontend/preset/seal-certificate.vue' /* webpackChunkName: "pages/frontend/preset/seal-certificate" */))
const _229441a7 = () => interopDefault(import('../pages/frontend/preset/soft-account.vue' /* webpackChunkName: "pages/frontend/preset/soft-account" */))
const _f7ac4b4e = () => interopDefault(import('../pages/frontend/request/company-register/index.vue' /* webpackChunkName: "pages/frontend/request/company-register/index" */))
const _5dfe1ba7 = () => interopDefault(import('../pages/frontend/request/digit-certificate/index.vue' /* webpackChunkName: "pages/frontend/request/digit-certificate/index" */))
const _180605a6 = () => interopDefault(import('../pages/frontend/request/seal-certificate/index.vue' /* webpackChunkName: "pages/frontend/request/seal-certificate/index" */))
const _69de6f24 = () => interopDefault(import('../pages/frontend/settings/account.vue' /* webpackChunkName: "pages/frontend/settings/account" */))
const _467bd929 = () => interopDefault(import('../pages/frontend/settings/card.vue' /* webpackChunkName: "pages/frontend/settings/card" */))
const _17f07974 = () => interopDefault(import('../pages/frontend/settings/company.vue' /* webpackChunkName: "pages/frontend/settings/company" */))
const _591e4202 = () => interopDefault(import('../pages/frontend/settings/coupon.vue' /* webpackChunkName: "pages/frontend/settings/coupon" */))
const _e8f66d4e = () => interopDefault(import('../pages/frontend/settings/digital-certificate.vue' /* webpackChunkName: "pages/frontend/settings/digital-certificate" */))
const _d446c6d2 = () => interopDefault(import('../pages/frontend/settings/seal-card.vue' /* webpackChunkName: "pages/frontend/settings/seal-card" */))
const _3db414dc = () => interopDefault(import('../pages/frontend/settings/seal-submitter.vue' /* webpackChunkName: "pages/frontend/settings/seal-submitter" */))
const _175417a3 = () => interopDefault(import('../pages/frontend/settings/soft-account.vue' /* webpackChunkName: "pages/frontend/settings/soft-account" */))
const _6d645238 = () => interopDefault(import('../pages/frontend/first-login/digital/seal-certificate.vue' /* webpackChunkName: "pages/frontend/first-login/digital/seal-certificate" */))
const _bb0dc154 = () => interopDefault(import('../pages/frontend/first-login/seal/digital-certificate.vue' /* webpackChunkName: "pages/frontend/first-login/seal/digital-certificate" */))
const _38c7dad4 = () => interopDefault(import('../pages/frontend/first-login/seal/seal-card.vue' /* webpackChunkName: "pages/frontend/first-login/seal/seal-card" */))
const _dad69bfa = () => interopDefault(import('../pages/frontend/first-login/seal/seal-certificate.vue' /* webpackChunkName: "pages/frontend/first-login/seal/seal-certificate" */))
const _24d3d446 = () => interopDefault(import('../pages/frontend/first-login/seal/soft-account.vue' /* webpackChunkName: "pages/frontend/first-login/seal/soft-account" */))
const _1c08157b = () => interopDefault(import('../pages/frontend/preset/digital/seal-certificate.vue' /* webpackChunkName: "pages/frontend/preset/digital/seal-certificate" */))
const _15994426 = () => interopDefault(import('../pages/frontend/preset/seal/digital-certificate.vue' /* webpackChunkName: "pages/frontend/preset/seal/digital-certificate" */))
const _5ecc1a2b = () => interopDefault(import('../pages/frontend/preset/seal/seal-card.vue' /* webpackChunkName: "pages/frontend/preset/seal/seal-card" */))
const _17a1d2cc = () => interopDefault(import('../pages/frontend/preset/seal/seal-certificate.vue' /* webpackChunkName: "pages/frontend/preset/seal/seal-certificate" */))
const _2d1ac18f = () => interopDefault(import('../pages/frontend/preset/seal/soft-account.vue' /* webpackChunkName: "pages/frontend/preset/seal/soft-account" */))
const _1531a753 = () => interopDefault(import('../pages/frontend/request/company-register/first-login.vue' /* webpackChunkName: "pages/frontend/request/company-register/first-login" */))
const _599193be = () => interopDefault(import('../pages/frontend/request/digit-certificate/first-login.vue' /* webpackChunkName: "pages/frontend/request/digit-certificate/first-login" */))
const _7d4c5b27 = () => interopDefault(import('../pages/frontend/request/seal-certificate/first-login.vue' /* webpackChunkName: "pages/frontend/request/seal-certificate/first-login" */))
const _5f4350cd = () => interopDefault(import('../pages/frontend/project/company-register/step1/_id.vue' /* webpackChunkName: "pages/frontend/project/company-register/step1/_id" */))
const _18f08064 = () => interopDefault(import('../pages/frontend/project/company-register/step2/_id.vue' /* webpackChunkName: "pages/frontend/project/company-register/step2/_id" */))
const _f067a262 = () => interopDefault(import('../pages/frontend/project/company-register/step3/_id.vue' /* webpackChunkName: "pages/frontend/project/company-register/step3/_id" */))
const _1c109dd0 = () => interopDefault(import('../pages/frontend/project/company-register/step4/_id.vue' /* webpackChunkName: "pages/frontend/project/company-register/step4/_id" */))
const _22f0d56f = () => interopDefault(import('../pages/frontend/project/digit-certificate/step1/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step1/_id" */))
const _91957720 = () => interopDefault(import('../pages/frontend/project/digit-certificate/step2/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step2/_id" */))
const _4b79b371 = () => interopDefault(import('../pages/frontend/project/digit-certificate/step3/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step3/_id" */))
const _4083bb1c = () => interopDefault(import('../pages/frontend/project/digit-certificate/step4/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step4/_id" */))
const _74029173 = () => interopDefault(import('../pages/frontend/project/digit-certificate/step5/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step5/_id" */))
const _08470074 = () => interopDefault(import('../pages/frontend/project/digit-certificate/step6/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step6/_id" */))
const _c6e92116 = () => interopDefault(import('../pages/frontend/project/digit-certificate/step7/_id.vue' /* webpackChunkName: "pages/frontend/project/digit-certificate/step7/_id" */))
const _cf3e2cbe = () => interopDefault(import('../pages/frontend/project/seal-certificate/step1/_id.vue' /* webpackChunkName: "pages/frontend/project/seal-certificate/step1/_id" */))
const _2ca558a2 = () => interopDefault(import('../pages/frontend/project/seal-certificate/step2/_id.vue' /* webpackChunkName: "pages/frontend/project/seal-certificate/step2/_id" */))
const _7e2c70ba = () => interopDefault(import('../pages/frontend/project/seal-certificate/step3/_id.vue' /* webpackChunkName: "pages/frontend/project/seal-certificate/step3/_id" */))
const _552e36a4 = () => interopDefault(import('../pages/frontend/project/seal-certificate/step4/_id.vue' /* webpackChunkName: "pages/frontend/project/seal-certificate/step4/_id" */))
const _143d37cc = () => interopDefault(import('../pages/admin/companies/_id.vue' /* webpackChunkName: "pages/admin/companies/_id" */))
const _3337df32 = () => interopDefault(import('../pages/admin/need_payment/_id.vue' /* webpackChunkName: "pages/admin/need_payment/_id" */))
const _5a2bb2ac = () => interopDefault(import('../pages/admin/revenues/_id.vue' /* webpackChunkName: "pages/admin/revenues/_id" */))
const _78d2edf9 = () => interopDefault(import('../pages/admin/users/_id.vue' /* webpackChunkName: "pages/admin/users/_id" */))
const _47512508 = () => interopDefault(import('../pages/frontend/projects/_id.vue' /* webpackChunkName: "pages/frontend/projects/_id" */))
const _a4aef260 = () => interopDefault(import('../pages/frontend/receipt/_id.vue' /* webpackChunkName: "pages/frontend/receipt/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _be9dc5d0,
    name: "admin"
  }, {
    path: "/frontend",
    component: _528b1e7f,
    name: "frontend"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms"
  }, {
    path: "/admin/change-sikulix-pass",
    component: _e278afee,
    name: "admin-change-sikulix-pass"
  }, {
    path: "/admin/companies",
    component: _29b76c64,
    name: "admin-companies"
  }, {
    path: "/admin/contacts",
    component: _85773680,
    name: "admin-contacts"
  }, {
    path: "/admin/login",
    component: _6a58c02f,
    name: "admin-login"
  }, {
    path: "/admin/need_payment",
    component: _74a5d24a,
    name: "admin-need_payment"
  }, {
    path: "/admin/payment_confirms",
    component: _f439320e,
    name: "admin-payment_confirms"
  }, {
    path: "/admin/revenues",
    component: _a41aa178,
    name: "admin-revenues"
  }, {
    path: "/admin/status",
    component: _3f2c361f,
    name: "admin-status"
  }, {
    path: "/admin/users",
    component: _808f695e,
    name: "admin-users"
  }, {
    path: "/frontend/contact",
    component: _64e3dce6,
    name: "frontend-contact"
  }, {
    path: "/frontend/contact-complete",
    component: _027b2eae,
    name: "frontend-contact-complete"
  }, {
    path: "/frontend/dashboard",
    component: _11b762f8,
    name: "frontend-dashboard"
  }, {
    path: "/frontend/debug-sentry",
    component: _7aa2e8fc,
    name: "frontend-debug-sentry"
  }, {
    path: "/frontend/detail",
    component: _d1a87e18,
    name: "frontend-detail"
  }, {
    path: "/frontend/digit",
    component: _0b1cc95a,
    name: "frontend-digit"
  }, {
    path: "/frontend/preset",
    component: _a9ab9cf6,
    name: "frontend-preset"
  }, {
    path: "/frontend/privacy",
    component: _24ba1cf5,
    name: "frontend-privacy"
  }, {
    path: "/frontend/request",
    component: _7a7049ff,
    name: "frontend-request"
  }, {
    path: "/frontend/seal",
    component: _38c2e760,
    name: "frontend-seal"
  }, {
    path: "/frontend/search",
    component: _aad0ea6a,
    name: "frontend-search"
  }, {
    path: "/frontend/search-form",
    component: _0100df94,
    name: "frontend-search-form"
  }, {
    path: "/frontend/settings",
    component: _6a18df09,
    name: "frontend-settings"
  }, {
    path: "/frontend/trade",
    component: _a0e563de,
    name: "frontend-trade"
  }, {
    path: "/frontend/verify",
    component: _d14445c8,
    name: "frontend-verify"
  }, {
    path: "/frontend/verify-email-change-email",
    component: _42fedfd1,
    name: "frontend-verify-email-change-email"
  }, {
    path: "/admin/auth/login",
    component: _0cb6f7ec,
    name: "admin-auth-login"
  }, {
    path: "/admin/revenue_reports/chart",
    component: _24810a34,
    name: "admin-revenue_reports-chart"
  }, {
    path: "/admin/revenue_reports/list",
    component: _13b25e1e,
    name: "admin-revenue_reports-list"
  }, {
    path: "/frontend/auth/login",
    component: _508e8236,
    name: "frontend-auth-login"
  }, {
    path: "/frontend/auth/register",
    component: _fb409852,
    name: "frontend-auth-register"
  }, {
    path: "/frontend/first-login/company",
    component: _197b35aa,
    name: "frontend-first-login-company"
  }, {
    path: "/frontend/first-login/digital",
    component: _cec83cf6,
    name: "frontend-first-login-digital"
  }, {
    path: "/frontend/first-login/seal",
    component: _efa27874,
    name: "frontend-first-login-seal"
  }, {
    path: "/frontend/preset/digital",
    component: _1df6db4e,
    name: "frontend-preset-digital"
  }, {
    path: "/frontend/preset/digital-certificate",
    component: _2db3da56,
    name: "frontend-preset-digital-certificate"
  }, {
    path: "/frontend/preset/seal",
    component: _71ee469d,
    name: "frontend-preset-seal"
  }, {
    path: "/frontend/preset/seal-card",
    component: _70c85113,
    name: "frontend-preset-seal-card"
  }, {
    path: "/frontend/preset/seal-certificate",
    component: _94a4f238,
    name: "frontend-preset-seal-certificate"
  }, {
    path: "/frontend/preset/soft-account",
    component: _229441a7,
    name: "frontend-preset-soft-account"
  }, {
    path: "/frontend/request/company-register",
    component: _f7ac4b4e,
    name: "frontend-request-company-register"
  }, {
    path: "/frontend/request/digit-certificate",
    component: _5dfe1ba7,
    name: "frontend-request-digit-certificate"
  }, {
    path: "/frontend/request/seal-certificate",
    component: _180605a6,
    name: "frontend-request-seal-certificate"
  }, {
    path: "/frontend/settings/account",
    component: _69de6f24,
    name: "frontend-settings-account"
  }, {
    path: "/frontend/settings/card",
    component: _467bd929,
    name: "frontend-settings-card"
  }, {
    path: "/frontend/settings/company",
    component: _17f07974,
    name: "frontend-settings-company"
  }, {
    path: "/frontend/settings/coupon",
    component: _591e4202,
    name: "frontend-settings-coupon"
  }, {
    path: "/frontend/settings/digital-certificate",
    component: _e8f66d4e,
    name: "frontend-settings-digital-certificate"
  }, {
    path: "/frontend/settings/seal-card",
    component: _d446c6d2,
    name: "frontend-settings-seal-card"
  }, {
    path: "/frontend/settings/seal-submitter",
    component: _3db414dc,
    name: "frontend-settings-seal-submitter"
  }, {
    path: "/frontend/settings/soft-account",
    component: _175417a3,
    name: "frontend-settings-soft-account"
  }, {
    path: "/frontend/first-login/digital/seal-certificate",
    component: _6d645238,
    name: "frontend-first-login-digital-seal-certificate"
  }, {
    path: "/frontend/first-login/seal/digital-certificate",
    component: _bb0dc154,
    name: "frontend-first-login-seal-digital-certificate"
  }, {
    path: "/frontend/first-login/seal/seal-card",
    component: _38c7dad4,
    name: "frontend-first-login-seal-seal-card"
  }, {
    path: "/frontend/first-login/seal/seal-certificate",
    component: _dad69bfa,
    name: "frontend-first-login-seal-seal-certificate"
  }, {
    path: "/frontend/first-login/seal/soft-account",
    component: _24d3d446,
    name: "frontend-first-login-seal-soft-account"
  }, {
    path: "/frontend/preset/digital/seal-certificate",
    component: _1c08157b,
    name: "frontend-preset-digital-seal-certificate"
  }, {
    path: "/frontend/preset/seal/digital-certificate",
    component: _15994426,
    name: "frontend-preset-seal-digital-certificate"
  }, {
    path: "/frontend/preset/seal/seal-card",
    component: _5ecc1a2b,
    name: "frontend-preset-seal-seal-card"
  }, {
    path: "/frontend/preset/seal/seal-certificate",
    component: _17a1d2cc,
    name: "frontend-preset-seal-seal-certificate"
  }, {
    path: "/frontend/preset/seal/soft-account",
    component: _2d1ac18f,
    name: "frontend-preset-seal-soft-account"
  }, {
    path: "/frontend/request/company-register/first-login",
    component: _1531a753,
    name: "frontend-request-company-register-first-login"
  }, {
    path: "/frontend/request/digit-certificate/first-login",
    component: _599193be,
    name: "frontend-request-digit-certificate-first-login"
  }, {
    path: "/frontend/request/seal-certificate/first-login",
    component: _7d4c5b27,
    name: "frontend-request-seal-certificate-first-login"
  }, {
    path: "/frontend/project/company-register/step1/:id?",
    component: _5f4350cd,
    name: "frontend-project-company-register-step1-id"
  }, {
    path: "/frontend/project/company-register/step2/:id?",
    component: _18f08064,
    name: "frontend-project-company-register-step2-id"
  }, {
    path: "/frontend/project/company-register/step3/:id?",
    component: _f067a262,
    name: "frontend-project-company-register-step3-id"
  }, {
    path: "/frontend/project/company-register/step4/:id?",
    component: _1c109dd0,
    name: "frontend-project-company-register-step4-id"
  }, {
    path: "/frontend/project/digit-certificate/step1/:id?",
    component: _22f0d56f,
    name: "frontend-project-digit-certificate-step1-id"
  }, {
    path: "/frontend/project/digit-certificate/step2/:id?",
    component: _91957720,
    name: "frontend-project-digit-certificate-step2-id"
  }, {
    path: "/frontend/project/digit-certificate/step3/:id?",
    component: _4b79b371,
    name: "frontend-project-digit-certificate-step3-id"
  }, {
    path: "/frontend/project/digit-certificate/step4/:id?",
    component: _4083bb1c,
    name: "frontend-project-digit-certificate-step4-id"
  }, {
    path: "/frontend/project/digit-certificate/step5/:id?",
    component: _74029173,
    name: "frontend-project-digit-certificate-step5-id"
  }, {
    path: "/frontend/project/digit-certificate/step6/:id?",
    component: _08470074,
    name: "frontend-project-digit-certificate-step6-id"
  }, {
    path: "/frontend/project/digit-certificate/step7/:id?",
    component: _c6e92116,
    name: "frontend-project-digit-certificate-step7-id"
  }, {
    path: "/frontend/project/seal-certificate/step1/:id?",
    component: _cf3e2cbe,
    name: "frontend-project-seal-certificate-step1-id"
  }, {
    path: "/frontend/project/seal-certificate/step2/:id?",
    component: _2ca558a2,
    name: "frontend-project-seal-certificate-step2-id"
  }, {
    path: "/frontend/project/seal-certificate/step3/:id?",
    component: _7e2c70ba,
    name: "frontend-project-seal-certificate-step3-id"
  }, {
    path: "/frontend/project/seal-certificate/step4/:id?",
    component: _552e36a4,
    name: "frontend-project-seal-certificate-step4-id"
  }, {
    path: "/admin/companies/:id",
    component: _143d37cc,
    name: "admin-companies-id"
  }, {
    path: "/admin/need_payment/:id",
    component: _3337df32,
    name: "admin-need_payment-id"
  }, {
    path: "/admin/revenues/:id",
    component: _5a2bb2ac,
    name: "admin-revenues-id"
  }, {
    path: "/admin/users/:id",
    component: _78d2edf9,
    name: "admin-users-id"
  }, {
    path: "/frontend/projects/:id?",
    component: _47512508,
    name: "frontend-projects-id"
  }, {
    path: "/frontend/receipt/:id?",
    component: _a4aef260,
    name: "frontend-receipt-id"
  }, {
    path: "/",
    component: _528b1e7f,
    name: "index"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms"
  }, {
    path: "/contact",
    component: _64e3dce6,
    name: "contact"
  }, {
    path: "/contact-complete",
    component: _027b2eae,
    name: "contact-complete"
  }, {
    path: "/dashboard",
    component: _11b762f8,
    name: "dashboard"
  }, {
    path: "/debug-sentry",
    component: _7aa2e8fc,
    name: "debug-sentry"
  }, {
    path: "/detail",
    component: _d1a87e18,
    name: "detail"
  }, {
    path: "/digit",
    component: _0b1cc95a,
    name: "digit"
  }, {
    path: "/preset",
    component: _a9ab9cf6,
    name: "preset"
  }, {
    path: "/privacy",
    component: _24ba1cf5,
    name: "privacy"
  }, {
    path: "/request",
    component: _7a7049ff,
    name: "request"
  }, {
    path: "/seal",
    component: _38c2e760,
    name: "seal"
  }, {
    path: "/search",
    component: _aad0ea6a,
    name: "search"
  }, {
    path: "/search-form",
    component: _0100df94,
    name: "search-form"
  }, {
    path: "/settings",
    component: _6a18df09,
    name: "settings"
  }, {
    path: "/trade",
    component: _a0e563de,
    name: "trade"
  }, {
    path: "/verify",
    component: _d14445c8,
    name: "verify"
  }, {
    path: "/verify-email-change-email",
    component: _42fedfd1,
    name: "verify-email-change-email"
  }, {
    path: "/auth/login",
    component: _508e8236,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _fb409852,
    name: "auth-register"
  }, {
    path: "/first-login/company",
    component: _197b35aa,
    name: "first-login-company"
  }, {
    path: "/first-login/digital",
    component: _cec83cf6,
    name: "first-login-digital"
  }, {
    path: "/first-login/seal",
    component: _efa27874,
    name: "first-login-seal"
  }, {
    path: "/preset/digital",
    component: _1df6db4e,
    name: "preset-digital"
  }, {
    path: "/preset/digital-certificate",
    component: _2db3da56,
    name: "preset-digital-certificate"
  }, {
    path: "/preset/seal",
    component: _71ee469d,
    name: "preset-seal"
  }, {
    path: "/preset/seal-card",
    component: _70c85113,
    name: "preset-seal-card"
  }, {
    path: "/preset/seal-certificate",
    component: _94a4f238,
    name: "preset-seal-certificate"
  }, {
    path: "/preset/soft-account",
    component: _229441a7,
    name: "preset-soft-account"
  }, {
    path: "/request/company-register",
    component: _f7ac4b4e,
    name: "request-company-register"
  }, {
    path: "/request/digit-certificate",
    component: _5dfe1ba7,
    name: "request-digit-certificate"
  }, {
    path: "/request/seal-certificate",
    component: _180605a6,
    name: "request-seal-certificate"
  }, {
    path: "/settings/account",
    component: _69de6f24,
    name: "settings-account"
  }, {
    path: "/settings/card",
    component: _467bd929,
    name: "settings-card"
  }, {
    path: "/settings/company",
    component: _17f07974,
    name: "settings-company"
  }, {
    path: "/settings/coupon",
    component: _591e4202,
    name: "settings-coupon"
  }, {
    path: "/settings/digital-certificate",
    component: _e8f66d4e,
    name: "settings-digital-certificate"
  }, {
    path: "/settings/seal-card",
    component: _d446c6d2,
    name: "settings-seal-card"
  }, {
    path: "/settings/seal-submitter",
    component: _3db414dc,
    name: "settings-seal-submitter"
  }, {
    path: "/settings/soft-account",
    component: _175417a3,
    name: "settings-soft-account"
  }, {
    path: "/first-login/digital/seal-certificate",
    component: _6d645238,
    name: "first-login-digital-seal-certificate"
  }, {
    path: "/first-login/seal/digital-certificate",
    component: _bb0dc154,
    name: "first-login-seal-digital-certificate"
  }, {
    path: "/first-login/seal/seal-card",
    component: _38c7dad4,
    name: "first-login-seal-seal-card"
  }, {
    path: "/first-login/seal/seal-certificate",
    component: _dad69bfa,
    name: "first-login-seal-seal-certificate"
  }, {
    path: "/first-login/seal/soft-account",
    component: _24d3d446,
    name: "first-login-seal-soft-account"
  }, {
    path: "/preset/digital/seal-certificate",
    component: _1c08157b,
    name: "preset-digital-seal-certificate"
  }, {
    path: "/preset/seal/digital-certificate",
    component: _15994426,
    name: "preset-seal-digital-certificate"
  }, {
    path: "/preset/seal/seal-card",
    component: _5ecc1a2b,
    name: "preset-seal-seal-card"
  }, {
    path: "/preset/seal/seal-certificate",
    component: _17a1d2cc,
    name: "preset-seal-seal-certificate"
  }, {
    path: "/preset/seal/soft-account",
    component: _2d1ac18f,
    name: "preset-seal-soft-account"
  }, {
    path: "/request/company-register/first-login",
    component: _1531a753,
    name: "request-company-register-first-login"
  }, {
    path: "/request/digit-certificate/first-login",
    component: _599193be,
    name: "request-digit-certificate-first-login"
  }, {
    path: "/request/seal-certificate/first-login",
    component: _7d4c5b27,
    name: "request-seal-certificate-first-login"
  }, {
    path: "/project/company-register/step1/:id?",
    component: _5f4350cd,
    name: "project-company-register-step1-id"
  }, {
    path: "/project/company-register/step2/:id?",
    component: _18f08064,
    name: "project-company-register-step2-id"
  }, {
    path: "/project/company-register/step3/:id?",
    component: _f067a262,
    name: "project-company-register-step3-id"
  }, {
    path: "/project/company-register/step4/:id?",
    component: _1c109dd0,
    name: "project-company-register-step4-id"
  }, {
    path: "/project/digit-certificate/step1/:id?",
    component: _22f0d56f,
    name: "project-digit-certificate-step1-id"
  }, {
    path: "/project/digit-certificate/step2/:id?",
    component: _91957720,
    name: "project-digit-certificate-step2-id"
  }, {
    path: "/project/digit-certificate/step3/:id?",
    component: _4b79b371,
    name: "project-digit-certificate-step3-id"
  }, {
    path: "/project/digit-certificate/step4/:id?",
    component: _4083bb1c,
    name: "project-digit-certificate-step4-id"
  }, {
    path: "/project/digit-certificate/step5/:id?",
    component: _74029173,
    name: "project-digit-certificate-step5-id"
  }, {
    path: "/project/digit-certificate/step6/:id?",
    component: _08470074,
    name: "project-digit-certificate-step6-id"
  }, {
    path: "/project/digit-certificate/step7/:id?",
    component: _c6e92116,
    name: "project-digit-certificate-step7-id"
  }, {
    path: "/project/seal-certificate/step1/:id?",
    component: _cf3e2cbe,
    name: "project-seal-certificate-step1-id"
  }, {
    path: "/project/seal-certificate/step2/:id?",
    component: _2ca558a2,
    name: "project-seal-certificate-step2-id"
  }, {
    path: "/project/seal-certificate/step3/:id?",
    component: _7e2c70ba,
    name: "project-seal-certificate-step3-id"
  }, {
    path: "/project/seal-certificate/step4/:id?",
    component: _552e36a4,
    name: "project-seal-certificate-step4-id"
  }, {
    path: "/projects/:id?",
    component: _47512508,
    name: "projects-id"
  }, {
    path: "/receipt/:id?",
    component: _a4aef260,
    name: "receipt-id"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
