import Vue from 'vue'
import {ValidationObserver, ValidationProvider, extend, localize, configure} from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('contain_char_num', {
  validate: value => {
    let result = new RegExp(/[a-z].*[0-9]|[0-9].*[a-z]/i).test(value)
    if (!result) {
      result = '半角英数字で入力してください。'
    }
    return result
  }
})

extend('minmax', {
  validate: (value, args) => {
    const length = value.length;
    if (length >= args.min && length <= args.max) {
      return true;
    }

    return `${args.min}桁または${args.max}桁の半角数字で入力してください。`;
  },
  params: ['min', 'max'],
})

localize('ja', ja)

configure({
  classes: {
    valid: 'is-valid', // model is valid
    invalid: 'is-invalid', // model is invalid
  }
})

Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
